import api_client from "../helpers/api_client.js";
import { useNavigate } from "react-router-dom";

//TODO: Remove this abd try to move the code to redirectIfLoggedIn
const RedirectIfLoggedIn = () => {
    const navigate = useNavigate();
    if (authentication.isUserLogginIn()) {
        navigate('/');
    }
}

const authentication = {
    storeUserSession: (user, props) => {
        const currentUser = { auth_token: user.auth_token, id: user.id, email: user.email };
        localStorage.setItem("user", JSON.stringify(currentUser));
    },
    getCurrentUser: () => {
        const user = localStorage.getItem("user");

        if (user) {
            return JSON.parse(user);
        }

        return {};
    },
    isUserLogginIn: () => {
        return authentication.getCurrentUser()?.email !== undefined;
    },
    redirectIfLoggedIn: () => RedirectIfLoggedIn(),
    clearUserSession: () => {
        localStorage.removeItem("user");
    },
    checkToken: async () => {
        try {
            const response = await api_client.get('/check_token');
            return response.status === 204;
        }
        catch (error) {
            return false;
        }
    }

}

export default authentication