import React from "react";
import CreateCollagePage from './pages/create_collage/CreateCollagePage.js'
import { ThemeProvider } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import config from "./config/config.js";
import { Collapse, CssBaseline } from "@mui/material";
import { Button, Grid } from "@mui/material";
import NavBarMenu from './components/NavBarMenu'
import Copyright from "./components/Copyright.js";
import { useState, useEffect } from "react";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundImage: `url(${config.publicUrl}/assets/bg.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        fontFamily: "Nunito",
        paddingBottom: "20px",
    },
    centeredText: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        alignContent: "center",
        fontFamily: "Nunito",
        textAlign: "center",
    },
    appbar: {
        background: "none",
    },
}));

const Home = props => {

    const classes = useStyles();

    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(true)
    }, [])

    return (
        <div className={classes.root}>
            <CssBaseline />


            <NavBarMenu transparent={true} />


            <div className={classes.centeredText}>


                <Collapse in={checked}
                    {...(checked ? { timeout: 2000 } : {})}
                    collapsedSize={50} >

                    <Grid className={classes.centeredText} container justify="space-between" direction="row">


                        <Grid item xs={12}>
                            <h1>
                                Create memories that will last for years<br />in one minute
                                <CssBaseline />
                            </h1>
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" color="success" mb={2} href="/#/letsgo">Get Started</Button>
                        </Grid>
                    </Grid>
                </Collapse>

            </div >


            <Copyright contrast />

        </div >

    )
};

export default Home