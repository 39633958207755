const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var validPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

const validation = {

    validEmail: email => {
        return email.match(validEmailRegex);
    },
    validPassword: password => {
        return password.match(validPasswordRegex);
    },
    validContactMessage: message => {
        return message.length >= 20 && message.match(/\S+\s*/g).length >= 5;
    },


}

export default validation