import { Card, Grid, Button, Container, CssBaseline, CardMedia, CardContent, Typography } from "@mui/material";
import api_client from "../../helpers/api_client";
import Copyright from "../../components/Copyright";
import { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles";
import InfiniteScroll from 'react-infinite-scroll-component'
import config from "../../config/config";
import useAuthUserRedirect from "../../hooks/useAuthUserRedirect";
import authentication from "../../helpers/authentication";
import { Buffer } from "buffer";
import Loader from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
    activitiesContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(12),
    },
    image: {
        opacity: 0.8,
        "&:hover": {
            opacity: 1,
            cursor: "pointer",
        },
    },
    button: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
        transition: 'opacity 0.3s ease',
    },
    buttonVisible: {
        opacity: 1,
    },

}));

const ActivitiesPage = () => {
    const navigator = useAuthUserRedirect();

    useEffect(() => {
        if (!authentication.isUserLogginIn()) {
            navigator.redirect('/signin');
        }
    }, []);

    const classes = useStyles();
    const [collages, setCollages] = useState([]);
    const [total, setTotal] = useState(0);

    const [from, setFrom] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [buttonVisible, setButtonVisible] = useState({});

    const fetchData = async () => {
        setTimeout(async () => {
            const activitiesResponse = await api_client.get(`/history?from=${from}&size=${config.activities.pageSize}`);


            if (activitiesResponse.status === 200) {
                setFrom(from + config.activities.pageSize);

                const newCollages = await getImages(activitiesResponse.data.items);

                setCollages([...collages, ...newCollages]);
                setIsLoading(false);
                setTotal(activitiesResponse.data.total)

            } else {
                setIsLoading(false);
            }
        }, 1000);
    }

    const getImages = async (collagesDetails) => {
        const getImageRequests = collagesDetails.map(async (image) => {
            const response = await api_client.get(`/images/${image.id}`, { responseType: "arraybuffer" })

            return { id: image.id, created_at: image.created_at, data: response.data };
        });

        let images = await Promise.all(getImageRequests);

        images = images.map((image) => {
            const buffer = Buffer.from(image.data, "binary");
            const base64image = buffer.toString("base64");

            return { id: image.id, creation_date: image.creation_date, data: base64image };
        })

        return images;
    }

    const handleMouseEnter = (event, id) => {
        event.preventDefault();
        setButtonVisible({ [id]: true })
    }

    const handleMouseLeave = (event) => {
        event.preventDefault();
        setButtonVisible({})
    }

    useEffect(() => {
        fetchData()

    }, [])

    return (

        <>
            <Container component="main" pt={4} className={classes.activitiesContainer}>
                <CssBaseline />

                {isLoading && <Loader />}

                {!isLoading &&
                    <InfiniteScroll
                        dataLength={total}
                        next={fetchData}
                        hasMore={!isLoading && total >= from}
                        loader={<Loader />}
                    >
                        <Grid container justify="space-evenly" spacing={2} onMouseLeave={(event) => handleMouseLeave(event)}>

                            {collages.map((image) => {

                                return <Grid item xs={6}>
                                    <Card>
                                        <CardMedia className={classes.image}
                                            component="img"
                                            image={`data:image/jpeg;charset=utf-8;base64,${image.data}`}
                                            title={image.created_at}
                                            onMouseEnter={(event) => handleMouseEnter(event, image.id)}
                                        />


                                        {buttonVisible[image.id] &&
                                            <Button download target="_blank"
                                                variant="contained" color="primary"
                                                className={`${classes.button}${classes.buttonVisible}`}
                                                href={`data:image/jpeg;charset=utf-8;base64,${image.data}`}
                                            >
                                                Download
                                            </Button>}

                                        <CardContent>
                                            <Typography variant="secondary">{image.created_at}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            })}
                        </Grid>
                    </InfiniteScroll>
                }

            </Container>

            <Copyright />
        </>
    )
}


export default ActivitiesPage;