import React, { useState } from "react";
import validation from "../../helpers/validation";
import api_client from "../../helpers/api_client";
import { makeStyles } from "@mui/styles";
import { Alert, Container, Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from "../../components/Copyright";
import ErrorMessage from "../../components/ErrorMessage";
import NavBarMenu from "../..//components/NavBarMenu";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


const RegistrationPage = () => {

    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const [serverErrors, setServerErrors] = useState([]);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const validate = () => {
        const newErrorMessages = {};

        if (!validation.validEmail(email)) {
            newErrorMessages.email = 'Email invalid';
        }

        if (!validation.validPassword(password)) {
            newErrorMessages.password = 'Password must be at least 8 characters long, contain one upper case and lower case character and one number';
        }

        if (password != passwordConfirmation) {
            newErrorMessages.passwordConfirmation = 'Password must be the same as password confirmation';
        }

        setErrorMessages(newErrorMessages);

        return Object.keys(newErrorMessages).length === 0;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (validate()) {
            const body = { email, password, password_confirmation: passwordConfirmation };

            api_client.post('/register', body)
                .then(_ => {
                    setShowSuccessAlert(true);
                })
                .catch(error => {
                    setServerErrors(error.response.data?.errors || []);
                });

        }
    }

    return <>
        <NavBarMenu />

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3">
                    Sign Up
                </Typography>
                <form className={classes.form} action="/register/" method="post" noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(event) => { setEmail(event.target.value); }}
                    />
                    <ErrorMessage error={errorMessages.email} />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password} onChange={event => setPassword(event.target.value)}
                    />
                    <ErrorMessage error={errorMessages.password} />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password_confirmation"
                        label="Confirm Password"
                        type="password"
                        id="passwpassword_confirmationord"
                        autoComplete="current-password"
                        value={passwordConfirmation} onChange={event => setPasswordConfirmation(event.target.value)}
                    />
                    <ErrorMessage error={errorMessages.passwordConfirmation} />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size="large"
                    >
                        Register
                    </Button>
                    <Grid container>

                        <Grid item>
                            <Link href="/#/signin" variant="body2">
                                {"Existing user? Login"}
                            </Link>
                        </Grid>
                    </Grid>
                    {serverErrors.map((error) => {
                        return <ErrorMessage error={error} />
                    })}

                    {showSuccessAlert &&
                        <Alert severity="success">You will have confirmation email in a few minutes, please check your inbox!</Alert>
                    }
                </form>


            </div>

            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    </>

};

export default RegistrationPage