import React, { useState, useEffect, useRef } from "react";
import validation from "../../helpers/validation";
import api_client from "../../helpers/api_client";
import authentication from "../../helpers/authentication";
import useAuthUserRedirect from "../../hooks/useAuthUserRedirect";
import { Container, Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from "../../components/Copyright";
import { makeStyles } from "@mui/styles";
import ErrorMessage from "../../components/ErrorMessage";
import { useSearchParams } from "react-router-dom";
import NavBarMenu from '../../components/NavBarMenu'


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginPage = () => {

    const classes = useStyles();

    const navigator = useAuthUserRedirect();

    const [queryParameters] = useSearchParams()

    const accountVerified = queryParameters.get('accountVerified') === 'true'

    useEffect(() => {
        navigator.redirectIfNeeded();
    }, []);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errorMessages, setErrorMessages] = useState({});


    const [serverError, setServerError] = useState('');

    const validate = () => {
        const newErrors = {};

        if (!validation.validEmail(email)) {
            newErrors.email = 'Invalid email';
        };

        if (!validation.validPassword(password)) {
            newErrors.password = 'Invalid password';
        };

        setErrorMessages(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (validate()) {
            const body = { email, password };

            api_client.post('/login', body)
                .then(response => {
                    handleSuccessfullLogin(response.data);
                })
                .catch(error => {
                    setServerError(error.response.data);
                });

        }
    }

    const handleSuccessfullLogin = response => {
        storeUser(response);

        let location = '/';

        const fromUploadForm = queryParameters.get('from_upload_form') == 'true';
        if (fromUploadForm) {
            location += '?from_upload_form=true';
        }

        window.location.href = location;
    }

    const storeUser = response => {
        authentication.storeUserSession({ auth_token: response.token, id: response.id, email: email });
    }

    return <>
        <NavBarMenu />

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                {accountVerified &&
                    <Alert severity="success">Your account is verified. You can log-in now</Alert>
                }
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3">
                    Sign in
                </Typography>
                <form className={classes.form} action="/login/" method="post" noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <ErrorMessage error={errorMessages.email} />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password} onChange={event => setPassword(event.target.value)}
                    />
                    <ErrorMessage error={errorMessages.password} />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size="large"
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/#/signup" variant="body2">
                                Don't have an account? Sign Up
                            </Link>
                        </Grid>
                    </Grid>
                    <ErrorMessage error={serverError} showAsAlert={true} />
                </form>
            </div>

            <Box mt={8}>
                <Copyright />
            </Box>

        </Container>
    </>
}


export default LoginPage
