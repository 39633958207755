import { TextareaAutosize, TextField, Container, Typography, CssBaseline, Button, Avatar, Alert } from "@mui/material";
import Copyright from "../../components/Copyright";
import validation from "../../helpers/validation";
import { useState } from "react";
import ErrorMessage from "../../components/ErrorMessage";
import { ContactSupport } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import api_client from "../../helpers/api_client";
import { LoadingButton } from "@mui/lab";
import NavBarMenu from "../../components/NavBarMenu";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(12),
    },
}));

const ContactPage = () => {

    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [errorMessages, setErrorMessages] = useState({});

    const [contactSuccessful, setContactSuccessful] = useState(false);

    const [serverError, setServerError] = useState('');

    const [loading, setLoading] = useState(false);


    const validate = () => {
        const newErrorMessages = {};

        if (!validation.validEmail(email)) {
            newErrorMessages.email = 'Email not valid';
        }

        if (!validation.validContactMessage(message)) {
            newErrorMessages.message = 'You need to include at least 5 words in your message';
        }

        setErrorMessages(newErrorMessages);

        return Object.keys(newErrorMessages).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validate()) {
            setLoading(true);
            await sendEmail(event);
        }
    };

    const sendEmail = async (event) => {
        const data = {
            email,
            subject: 'Contact Request',
            message,
        }

        api_client.post('/contact_us', data)
            .then(_ => {
                setContactSuccessful(true);
                setLoading(false);
            })
            .catch(error => {
                setServerError(error.response.data);
                setLoading(false);
            });
    }

    return (
        <>
            <NavBarMenu />

            <Container component="main" maxWidth="xs" pt={4}>
                <CssBaseline />

                <div className={classes.paper}>

                    <Typography align="center" color="textPrimary" gutterBottom mb={4}>
                        We value your feedback!
                        All ideas, suggestions and questions are welcome.
                        Please reach out in the form below.
                        You can expect the answer in 48 hours!
                    </Typography>

                    <Avatar>
                        <ContactSupport />
                    </Avatar>

                    <form onSubmit={event => handleSubmit(event)}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                        {errorMessages.email && <ErrorMessage error={errorMessages.email} />}

                        <TextareaAutosize
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="message"
                            label="Your message"
                            type="message"
                            id="message"
                            value={message}
                            onChange={event => setMessage(event.target.value)}
                            minRows={10}
                            className={classes.contactUsMessage}
                            cols={60}
                        >
                        </TextareaAutosize>
                        {errorMessages.message && <ErrorMessage error={errorMessages.message} />}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={loading || contactSuccessful}
                        >
                            Send
                        </Button>

                    </form>

                    {contactSuccessful && <Alert severity="success">Message sent successfully. Expect our answer soon</Alert>}

                    {contactSuccessful && <Alert severity="error">{serverError}</Alert>}

                    {loading && <LoadingButton fullWidth={true} sizeLarge={true} iconSizeLarge={true} loading size="large" m={4}></LoadingButton>}

                </div>

            </Container>

            <Copyright />
        </>
    )
}

export default ContactPage;