import { useNavigate } from 'react-router-dom';
import authentication from '../helpers/authentication';

const useAuthUserRedirect = () => {
  const navigate = useNavigate();

  const redirectIfNeeded = () => {
    if (authentication.isUserLogginIn()) {
      return navigate('/');
    }
  }

  const redirect = (path) => {
    return navigate(path);
  }

  return { redirectIfNeeded, redirect };
  
}

export default useAuthUserRedirect;