import React, { useState, useEffect, useMemo } from "react";
import LoginPage from "./pages/login/LoginPage.js";
import RegistrationPage from "./pages/registration/RegistrationPage.js";

// Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import Layout from './Layout';
import Home from './Home'
import ContactPage from './pages/contact/ContactPage.js';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import AccountVerificationPage from './pages/account_verification/AccountVerificationPage.js';
import authentication from './helpers/authentication';
import SessionProvider from './hooks/SessionProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ActivitiesPage from './pages/activities/ActivitiesPage.js';
import CreateCollagePage from './pages/create_collage/CreateCollagePage.js';
import AboutPage from './pages/about/AboutPage';
import DonatePage from './pages/donate/DonatePage.js';
import api_client from "./helpers/api_client.js";

const theme = createTheme();

function App() {


  const logout = () => {
    //TODO: Extract API calls in hooks ?
    api_client.post(`/logout`, {})
      .then(_ => {
        clearUserSession();

        window.location.href = '/';
      })
      .catch(_ => {
        //TODO: Show error toast/alert ?
      })



  }

  const clearUserSession = () => {
    setSession({});
    authentication.clearUserSession();
  }

  const [session, setSession] = useState({ user: authentication.getCurrentUser(), logout: logout })

  useEffect(() => {
    const checkAuthToken = async () => {
      const isValid = await authentication.checkToken();

      if (!isValid) {
        setSession({});
        clearUserSession();
      }
    }

    if (session?.user?.email) {
      const intervalId = setInterval(checkAuthToken, 3000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <SessionProvider session={session}>
        <Router>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/letsgo' element={<CreateCollagePage />} />
            <Route exact path='/mywork' element={<ActivitiesPage />} />
            <Route exact path='/contact' element={<ContactPage />} />
            <Route exact path='/about' element={<AboutPage />} />
            <Route exact path='/donate' element={<DonatePage />} />

            <Route path='/signin' element={<LoginPage setSession={setSession} session={session} />} />
            <Route path='/signup' element={<RegistrationPage setSession={setSession} session={session} />} />
            <Route path='/account_verification/:verificationCode' element={<AccountVerificationPage />} />
          </Routes>
        </Router>

        <Layout>
        </Layout>
      </SessionProvider>
    </ThemeProvider>

  );
}

export default App;
