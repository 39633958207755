import { AppBar, CssBaseline, Toolbar, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import MenuDrawer from "./MenuDrawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserContext from "../contexts/UserContext";
import { useContext, useState } from "react";
import { AccountBox } from "@mui/icons-material";
import useLocalStorage from "../hooks/useLocalStorage";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    fontFamily: "Nunito",
    fontSize: "2.5em",

    '& a': {
      textDecoration: "none",
      color: "white",
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    "&:hover": {
      color: "white",
    },
  },
  dropdown: {
    backgroundColor: "white",
    p: 2,
  },
  dropdownLink: {
    color: "black",
    textDecoration: "none",
    fontSize: "20px",

  },
  toolbar: {
    '& > *': {
      margin: 0, // Remove top and bottom margins
    },
    display: 'flex',

  },
  accountMenuButton: {
    '&:hover': {
      borderRadius: theme.shape.borderRadius, // Maintain the original border radius
      backgroundColor: theme.palette.action.hover,
    },
  },
  lightAccountMenuButton: {
    backgroundColor: 'white', //theme.palette.background.paper, // Use the background color of your light theme
    color: theme.palette.primary.main, // Use the text color of your light theme
  },

  appbar: {
    fontFamily: "Nunito",
  },

  transparentAppbar: {
    backgroundColor: "transparent !important",
  },
  coloredLogo: {
    color: "blue",
  }
}));


const NavBarMenu = ({ transparent }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const userContext = useContext(UserContext);
  const [anchorElement, setAnchorElement] = useState(null);

  const openAccountMenu = (event) => {
    setAnchorElement(event.currentTarget);
  }
  const closeAccountMenu = () => {
    setAnchorElement(null);
  }

  const accountMenuOpen = Boolean(anchorElement);
  const id = accountMenuOpen ? 'simple-popover' : undefined;

  const [queryParameters] = useSearchParams()
  const fromUploadForm = queryParameters.get('from_upload_form') == 'true';

  //TODO: remove this fron navbar menu, move to app or whenever needed
  const storage = useLocalStorage();

  if (!fromUploadForm) {
    storage.clearImages();
  }


  return (
    <AppBar className={transparent ? classes.transparentAppbar : classes.appBar} position="static">

      <CssBaseline />
      <Toolbar className={classes.toolbar}>
        <h1 className={classes.logo}>
          <CssBaseline />
          <Link to="/">Image <span className={classes.coloredLogo}>Collager</span></Link>
        </h1>
        {/* </Typography> */}
        {isMobile ? (
          <MenuDrawer />
        ) : (
          <>
            <div className={classes.navlinks}>
              <MenuItem>
                <Link to="/" className={classes.link}>
                  Home
                </Link>
              </MenuItem>

              <MenuItem>
                <Link to="/letsgo" className={classes.link}>
                  Get Started
                </Link>
              </MenuItem>

              {userContext?.user?.email &&
                <MenuItem>
                  <Link to="/mywork" className={classes.link}>
                    My Work
                  </Link>
                </MenuItem>
              }

              <MenuItem>
                <Link to="/about" className={classes.link}>
                  How It Works
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/contact" className={classes.link}>
                  Feedback & Contact
                </Link>
              </MenuItem>

              <MenuItem>
                <Link to="/donate" className={classes.link}>
                  Support Us
                </Link>
              </MenuItem>

              {(!userContext || userContext?.length === 0 || !userContext?.user?.email) && <MenuItem><Link to="/signin" className={classes.link}>Sign in</Link></MenuItem>}

              {userContext.user.email && (
                <>
                  <IconButton aria-describedby={id} clasName={classes.accountMenuButton} variant="contained" onClick={openAccountMenu}>
                    <AccountBox className={classes.lightAccountMenuButton} />

                  </IconButton>
                </>
              )}

            </div>
            <Menu
              id={id}
              open={accountMenuOpen}
              anchorEl={anchorElement}
              onClose={closeAccountMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography className={classes.dropdown}>
                <MenuItem><Link to="#" className={classes.dropdownLink} onClick={userContext.logout}>Profile</Link></MenuItem>

                <MenuItem><Link to="#" className={classes.dropdownLink} onClick={userContext.logout}>Logout</Link></MenuItem>

              </Typography>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar >
  );
}

export default NavBarMenu;