import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(()=>({
    link:{
        textDecoration:"none",
        color: "blue",
        fontSize: "20px",
    },
    icon:{
        color: "white"
    }
}));

const DrawerComponent = () => {
    const classes = useStyles();

    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
        <Drawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
        >
            <List>
                <ListItem onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/">Home</Link>
                    </ListItemText>
                </ListItem>
                <ListItem onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/signin">Sign In</Link>
                    </ListItemText>
                </ListItem>
                <ListItem onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/about">About</Link>
                    </ListItemText>
                </ListItem>
                <ListItem onClick={() => setOpenDrawer(false)}>
                    <ListItemText>
                    <Link to="/contact">Contact</Link>
                    </ListItemText>
                </ListItem>
            </List>
        </Drawer>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <Menu />
        </IconButton>
        </>
    );
}
export default DrawerComponent;