import React from 'react'
// import Navbar from './components/NavBar'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "Nunito",
    },
}));

const Layout = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className="container mt-5">
                {children}
            </div>
        </div>
    )
}
export default Layout