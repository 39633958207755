import UserContext from "../contexts/UserContext";

//TODO: Check if we can remove this provider and use UserContext.Provider in components directly
const SessionProvider = ({ session, children }) => {

    return (
        <UserContext.Provider value={session}>
            {children}
        </UserContext.Provider>
    )
};

export default SessionProvider