import { Container } from '@mui/material';
import NavBarMenu from '../../components/NavBarMenu'

const DonatePage = () => {
    return <>
        <NavBarMenu />

        <Container component="main" maxWidth="xs">

            <span>Page under construction...</span>

        </Container>
    </>
}

export default DonatePage