import React, { useState, useRef, useEffect, useContext } from "react";
import api_client from "../../helpers/api_client";
import { Button, Tooltip, Select, Box, Grid, Typography, TextField, FormLabel, RadioGroup, Radio, FormControlLabel, FormGroup, MenuItem, Alert } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Copyright from "../../components/Copyright";
import UserContext from "../../contexts/UserContext";
import NavBarMenu from "../../components/NavBarMenu";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
    },
    shapeWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    mainGridNoOutput: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    removeButton: {
        opacity: 0.7,

        "&:hover": {
            opacity: 1,
        },
    },
    uploadFormNoOutput: {

    },
    uploadFormWithOutput: {
        width: "20%",
        paddingRight: "30px",
    },
    outputSectionNoOutput: {

    },
    outputSectionWithOutput: {
        "width": "70%",
    },
}));

const CreateCollagePage = () => {

    const classes = useStyles();

    const userContext = useContext(UserContext)


    let storedUploadedBinaries = []

    // TODO: Currently, we're not saving uploaded files in local storage, since it takes too much space and its cleared out automatically
    // if (localStorage.getItem("uploaded_binaries")) {
    //     const binaryDataStrings = localStorage.getItem("uploaded_binaries").split(';');
    //     const storedUploadedBinaries = binaryDataStrings.map(dataString => new Uint8Array(dataString.split(',').map(Number)));
    //     console.log(localStorage.getItem("uploaded_binaries"))
    // }

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [width, setWidth] = useState(1000);
    const [height, setHeight] = useState(500);

    const selectedFilesRef = useRef();
    selectedFilesRef.current = selectedFiles;

    const [output, setOutput] = useState(localStorage.getItem("output"));

    const [loading, setLoading] = useState(false);

    const [saving, setSaving] = useState(false);

    const [saved, setSaved] = useState(false);

    const [previewUrls, setPreviewUrls] = useState([]);

    const [uploadedBinaries, setUploadedBinaries] = useState(storedUploadedBinaries)

    const [rowsCount, setRowsCount] = useState(1);

    const [shape, setShape] = useState('rectangle'); // Initialize with an empty string or the default value you want.

    const handleShapeChange = (event) => {
        setShape(event.target.value);
    };

    useEffect(() => {
        // TODO: Setting local storage is temporary disabled, since it takes to much space and its automatically cleaned on each refresh
        // for now, we'll not save files, just the output
        // const serializedBinaries = uploadedBinaries.map(arr => Array.from(arr).join(",")).join(";")
        // localStorage.setItem("uploaded_binaries", serializedBinaries)

        prepareUploadedFilesPreview();

    }, [uploadedBinaries]);

    useEffect(() => {
        if (output) {
            localStorage.setItem("output", output)
        }
    }, [output]);

    const createImageCollage = async event => {
        event.preventDefault();

        const data = new FormData();
        selectedFiles.forEach((file) => {
            data.append(`image`, file, file.name);
        })
        data.append('width', 1000)
        data.append('height', 500)
        data.append('rows_count', rowsCount);
        data.append('shape', shape)

        setLoading(true)
        const response = await api_client.post(
            '/upload',
            data,
            { responseType: "arraybuffer", headers: { "Content-Type": "multipart/form-data" } }
        );

        const base64 = arrayBufferToBase64(response.data);
        setOutput(base64)

        // const buffer = Buffer.from(response.data, "binary")
        // setOutput(buffer.toString("base64"))
        setLoading(false)
    }

    function arrayBufferToBase64(arrayBuffer) {
        const uint8Array = new Uint8Array(arrayBuffer);

        let binaryString = '';
        uint8Array.forEach((byte) => {
            binaryString += String.fromCharCode(byte);
        });
        return btoa(binaryString);
    }

    const handleFileChange = (event) => {
        // const uploadedFiles = Array.prototype.slice.call(event.target.files);

        const uploadedFiles = Array.from(event.target.files);

        setSelectedFiles(uploadedFiles);

        Promise.all(
            uploadedFiles.map(file => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const result = e.target.result;
                        const binaryData = new Uint8Array(result);
                        resolve(binaryData);
                    };
                    reader.readAsArrayBuffer(file);
                });
            })
        ).then(binaryArrayBufferList => {
            setUploadedBinaries(binaryArrayBufferList);
        });

    }

    const prepareUploadedFilesPreview = () => {
        // setPreviewUrls(selectedFilesRef.current.map((file) => filePreview(file)));
        setPreviewUrls(uploadedBinaries.map((file) => filePreview(new Blob([file]))));
    }

    const filePreview = (file) => {
        return URL.createObjectURL(file);
    }

    const removeImage = (_, index) => {
        setSelectedFiles((current) =>
            current.filter((_, i) => i !== index)
        );
    };

    const saveCollage = async (event) => {
        const data = new FormData();
        data.append(`collage`, base64ToBlob(output, 'image/jpeg'), `mycollage.jpg`);

        setSaving(true)
        const response = await api_client.post('/save', data, { responseType: "arraybuffer", headers: { "Content-Type": "multipart/form-data" } })

        //TODO: use response to check if request successful and show message/UI accordingly
        setSaving(false);
        setSaved(true)
    };

    const base64ToBlob = (base64, mime) => {
        const bstr = atob(base64);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };


    const handleRowsCountChange = (event) => {
        setRowsCount(event.target.value);
    }

    return (
        <>
            <NavBarMenu />
            <Grid row pt={10} className={classes.mainGridNoOutput}>

                <Grid item xs={12} mb={5} className={(loading || output) ? classes.uploadFormWithOutput : classes.uploadFormNoOutput}>
                    <Box>
                        <form action="/upload" method="post" className={classes.form} width="100vw" onSubmit={createImageCollage}>

                            <Grid container rowSpacing={2} mb={5}>
                                <Grid item xs={12} pb={8}>

                                    {
                                        <Button variant="contained" component="label" size="large">
                                            Choose files
                                            <input hidden accept="image/*" multiple type="file" onChange={handleFileChange} />
                                        </Button>
                                    }
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        id="width"
                                        label="Width"
                                        name="width"
                                        autoFocus
                                        value={width}
                                        type="number"
                                        onChange={event => setWidth(event.target.value)}
                                        disabled={selectedFiles.length === 0}
                                    />
                                </Grid>


                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        id="height"
                                        label="Height"
                                        name="height"
                                        autoFocus
                                        value={height}
                                        type="number"
                                        onChange={event => setHeight(event.target.value)}
                                        disabled={selectedFiles.length === 0}
                                    />
                                </Grid>

                                <Grid item xs={12} mt={3}>
                                    <FormGroup className={classes.shapeWrapper}>
                                        <FormLabel id="demo-radio-buttons-group-label">Shape</FormLabel>
                                        <RadioGroup row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={shape}
                                            name="radio-buttons-group"
                                            onClick={handleShapeChange}
                                        >
                                            <FormControlLabel value="rectangle" control={<Radio disabled={selectedFiles.length === 0} />} label="Rectangle" />
                                            <FormControlLabel value="circle" control={<Radio disabled={selectedFiles.length === 0} />} label="Circle" />
                                        </RadioGroup>
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={3} mt={3}>
                                    <FormGroup className={classes.shapeWrapper}>
                                        {/* <FormLabel id="demo-radio-buttons-group-label">Rows Count</FormLabel>
                                        <select
                                            value={selectedFruit} // ...force the select's value to match the state variable...
                                            onChange={e => setSelectedFruit(e.target.value)} // ... and update the state variable on any change!
                                        >
                                            <option value="apple">Apple</option>
                                            <option value="banana">Banana</option>
                                            <option value="orange">Orange</option>
                                        </select> */}

                                        <FormLabel id="demo-radio-buttons-group-label">Rows Count</FormLabel>
                                        <Select
                                            labelId="rows-count"
                                            id="rows-count"
                                            value={rowsCount}
                                            label="Rows Count"
                                            onChange={handleRowsCountChange}
                                        >
                                            <MenuItem value={1}>One</MenuItem>
                                            <MenuItem value={2}>Two</MenuItem>
                                            <MenuItem value={3}>Three</MenuItem>
                                            <MenuItem value={4}>Four</MenuItem>
                                            <MenuItem value={5}>Five</MenuItem>
                                        </Select>
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                    {<Button size="large" color="primary" variant="outlined" type="submit" disabled={selectedFiles.length === 0}>Make my collage</Button>}
                                </Grid>

                            </Grid>

                            <Grid container spacing={1}>
                                {previewUrls.map((url, index) => {
                                    return <Grid item>
                                        {/* <img src={url} width="100px" height="100px" /> */}
                                        <Button className={classes.removeButton} aria-label="delete" size="small" onClick={event => removeImage(event, index)}>
                                            <Tooltip title="Click to remove" placement="top-start">
                                                <img src={url} width="100px" height="100px" />
                                            </Tooltip>
                                        </Button>
                                    </Grid>
                                })}
                            </Grid>
                        </form>
                    </Box>

                </Grid>

                {(loading || output) &&
                    <Grid item className={(loading || output) ? classes.outputSectionWithOutput : classes.outputSectionNoOutput}>
                        <Grid item>
                            {output && !loading &&
                                <Grid container justify="space-between" spacing={24} direction="row" mb={3}>
                                    <Grid item xs={6}>
                                        <Button download target="_blank" variant="contained" color="success" mb={2} href={`data:image/jpeg;charset=utf-8;base64,${output}`}>Download image collage</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body" color="textPrimary" align="center">

                                            {
                                                (!userContext || userContext.length === 0 || !userContext?.user?.email) &&
                                                <>
                                                    <Button component={Link} variant="contained" to='/#/signin?from_upload_form=true'>Login</Button> or &nbsp;
                                                    <Button component={Link} variant="contained" to='/#/signup?from_upload_form=true'>Register</Button> to save your work
                                                </>

                                            }

                                            <Grid container>

                                                {saving && <Grid item><LoadingButton fullWidth={true} sizeLarge={true} iconSizeLarge={true} loading size="large" m={4}></LoadingButton></Grid>}

                                                {saved && <Grid item><Alert severity="success">Collage saved</Alert></Grid>}

                                                {
                                                    userContext?.user?.email &&
                                                    <Grid item><Button variant="contained" disabled={saving || saved} onClick={event => saveCollage(event)}>Save image collage</Button></Grid>
                                                }
                                            </Grid>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item>
                            {loading && <LoadingButton fullWidth={true} sizeLarge={true} iconSizeLarge={true} loading size="large" m={4}></LoadingButton>}

                            {output && !loading && <img src={`data:image/jpeg;charset=utf-8;base64,${output}`} />}
                        </Grid>
                    </Grid>
                }

            </Grid>

            <Copyright />
        </>
    )
}

export default CreateCollagePage