import { ClassNames } from "@emotion/react";
import { Typography, Link, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  copyright: {
    color: theme.palette.text.secondary,
  },
  copyrightContrast: {
    color: "white",
    fontWeight: "bold",
  }
}));

const Copyright = ({ contrast }) => {
  const classes = useStyles();

  return (
    <Box mb={5}>
      <Typography className={contrast ? classes.copyrightContrast : ClassNames.copyright} align="center">
        {'Copyright © '}
        <Link color="inherit" href="/">
          ImageCollager
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
}

export default Copyright;