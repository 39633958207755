const useLocalStorage = () => {

  const clearImages = () => {
    localStorage.removeItem('output');
  }

  return { clearImages };

}

export default useLocalStorage;