import axios from "axios";
import authentication from "./authentication";
import config from "../config/config";

const api_client = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'Content-Type': 'application/json',
    }
});

api_client.interceptors.request.use(function (config) {
    const token = authentication.getCurrentUser()?.auth_token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

export default api_client;