import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api_client from "../../helpers/api_client.js";
import useAuthUserRedirect from "../../hooks/useAuthUserRedirect.js";

const AccountVerificationPage = () => {

    //TODO: Rename this variable
    const navigator = useAuthUserRedirect();


    const { verificationCode } = useParams()

    const [verificationSuccessfull, setVerificationSuccessfull] = useState(null)

    useEffect(() => {
        api_client.post(`/verify_account`, { verification_code: verificationCode })
            .then(_ => {
                setVerificationSuccessfull(true)
            })
            .catch(_ => setVerificationSuccessfull(false))
    }, [])

    if (verificationSuccessfull === true) {
        navigator.redirect('/signin?accountVerified=true');

    } else if (verificationSuccessfull === false) {
        return <p>Invalid or expired verification code</p>
    }
    else {
        return <p>We are verifying your account...</p>
    }

}

export default AccountVerificationPage