import Copyright from "../../components/Copyright";
import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import config from "../../config/config";
import NavBarMenu from "../../components/NavBarMenu";
import { Container, Grid, ImageList, ImageListItem, Typography, Fade, Box, Button } from "@mui/material";

const useStyles = makeStyles(theme => ({
    main: {
        paddingTop: theme.spacing(4),
        fontFamily: "Nunito",

        "& h5": {
            fontFamily: "Nunito",
        }

    },
    imageMaxWidth: {
        maxWidth: "500px",
        height: "300px",
    },
    title: {
        marginBottom: "30px !important",
        marginTop: "20px !important",
    },
    centered: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textAlign: "center",
    },
    spaceAround: {
        marginTop: "20px",
        marginBottom: "20px",
    }
})
)

const AboutPage = () => {

    const timeout = 1500

    const [checked, setChecked] = useState([])


    useEffect(() => {
        const intervalId = setInterval(() => {
            setChecked((prevItems) => [...prevItems, true]);
        }, timeout)

        return () => {
            clearInterval(intervalId);
        };


    }, [])

    const classes = useStyles();

    const handleTransitionEnd = () => {
        buttonRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const buttonRef = useRef(null)



    return <>
        <NavBarMenu />

        <Container component="main">
            <Box className={classes.main}>
                <Typography variant="h5" className={classes.title}>With ImageCollager, you can easily transform this</Typography>


                <ImageList sx={{ width: 1000 }} cols={6}>

                    {Array.from({ length: 6 }, (_, i) => i + 1).map(index => {
                        return <ImageListItem>
                            <img src={config.publicUrl + '/assets/get-started' + index + '.jpg'} />
                        </ImageListItem>
                    })}

                </ImageList>

                <Grid container>
                    <Grid item xs={6} className={classes.aboutColumn}>
                        <Fade in={checked[1]} {...(checked[1] ? { timeout: 2000 } : {})}>
                            <Typography variant="h5" className={classes.title}>into this</Typography>
                        </Fade>

                        <Fade in={checked[2]} {...(checked[2] ? { timeout: 2000 } : {})}>
                            <img src={config.publicUrl + '/assets/rectangle.png'} className={classes.imageMaxWidth} />
                        </Fade>

                    </Grid>

                    <Grid item xs={6} className={classes.aboutColumn}>
                        <Fade in={checked[3]} {...(checked[3] ? { timeout: 2000 } : {})}>

                            <Typography variant="h5" className={classes.title}>or this</Typography>
                        </Fade>

                        <Fade in={checked[4]} {...(checked[4] ? { timeout: 2000 } : {})}>

                            <img src={config.publicUrl + '/assets/circle.png'} className={classes.imageMaxWidth} />
                        </Fade>
                    </Grid>
                </Grid>

                <Fade in={checked[5]} {...(checked[5] ? { timeout: 2000 } : {})}>

                    <Typography variant="h5" className={classes.title}>or anything you can imagine</Typography>
                </Fade>

                <Fade in={checked[6]} {...(checked[6] ? { timeout: 2000 } : {})} onTransitionEnd={handleTransitionEnd}>
                    <Grid item xs={12} className={`${classes.centered} ${classes.spaceAround}`} mt={30} mb={5}>
                        <Button ref={buttonRef} variant="contained" color="success" mb={2} href="/#/letsgo">Make collage</Button>
                    </Grid>
                </Fade>

            </Box>
        </Container >

        <Copyright />
    </>
}

export default AboutPage
